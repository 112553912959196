import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following table lists the metrics of Content aggregation panel, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Category > Sub-category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metrics`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Detail Type (experience)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > Content Event`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Numeric`}</b>{`: TBD`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Detail Type (segmentation)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > Content Event`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Numeric`}</b>{`: TBD`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Numeric`}</b>{`: TBD`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (baseline)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Numeric`}</b>{`: TBD`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with A/B variants)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Numeric`}</b>{`: TBD`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with profiles)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Numeric`}</b>{`: TBD`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with segments)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Numeric`}</b>{`: TBD`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique contents`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > Content Event`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Numeric`}</b>{`: TBD`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique elements`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > Content Event`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Numeric`}</b>{`: TBD`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique sessions (with prints)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Numeric`}</b>{`: TBD`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors (with prints)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > all`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Numeric`}</b>{`: TBD`}</li></ul></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      